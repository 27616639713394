import { useCallback, useState } from 'react';
// import errorReporter from 'utils/libs/errorReporter';
// import errorHandlerGraphql from 'utils/libs/errorHandlerGraphql';
import bodyMessageErrorForm from 'components/BodyMessageErrorForm';

const defaultProperties = {
  isOpen: false,
  title: null,
  body: 'Message',
  footer: null,
  'data-testid': '',
};

const usePopup = () => {
  const elementIframe = document.getElementsByTagName('iframe');
  const [properties, setProperties] = useState(defaultProperties);
  // const errorCodeNonValidation = ['500', '502', '503', '504'];
  const showPopup = useCallback(spec => {
    setProperties(prevState => ({
      ...prevState,
      isOpen: true,
      size: spec.size || 'md',
      title: spec.title || defaultProperties.title,
      body: spec.body || defaultProperties.body,
      footer: spec.footer || defaultProperties.footer,
      'data-testid': spec['data-testid'] || defaultProperties['data-testid'],
    }));
  }, []);

  const hidePopup = useCallback(() => {
    setProperties(prevState => ({
      ...prevState,
      isOpen: false,
    }));

    document.body.classList.remove('modal-open');
    if (elementIframe.length >= 1) {
      elementIframe[0].remove();
    }
  }, []);

  const catchPopup = useCallback(
    err => {
      const errorData = err?.message;
      let isUploadBody = false;
      const errorMessage =
        err.type === 'traefik' ? (errorData?.error ? errorData?.error?.message : errorData?.message) : false;
      const fieldsError = (errorData?.extensions ? errorData?.extensions?.errors : errorData?.error?.errors) || null;
      const isValidation =
        !!(errorData?.extensions ? errorData?.extensions?.errors : errorData?.error?.errors) || false;
      const errId = err?.message?.extensions?.id || '-';
      if (err.isUpload && err.message.includes('401')) {
        isUploadBody = true;
      } else {
        isUploadBody = false;
      }
      showPopup({
        isOpen: true,
        title: <span className="red">Error</span>,
        body: bodyMessageErrorForm({
          errorMessage: errorMessage || err.message,
          fieldsError,
          isUploadBody,
          isValidation,
          errId,
        }),
        'data-testid': err['data-testid'] || defaultProperties['data-testid'],
      });
    },
    [showPopup],
  );

  const catchFieldsError = (err, key) => {
    const error = {
      'fund-ops': err?.response?.data?.error?.errors ?? err?.response?.data?.errors,
      los: err?.response?.data?.error?.errors ?? err?.response?.data?.errors,
    };
    return error[key] ?? null;
  };

  const catchPopupError = useCallback(
    (err, prefix) => {
      const isUploadBody = false;
      const errorMessage = err?.response?.data?.errors?.message || err?.response?.data?.message;
      const fieldsError = catchFieldsError(err, prefix);
      const isValidation = !!catchFieldsError(err, prefix) || false;
      const errId = err?.response?.data?.errors?.id || '-';
      const errString =
        typeof err?.response?.data?.error === 'string'
          ? err?.response?.data?.error || '-'
          : err?.response?.data?.error?.message || '-';

      showPopup({
        isOpen: true,
        title: <span className="red">Error</span>,
        body: bodyMessageErrorForm({
          errorMessage: errorMessage || err.message,
          fieldsError,
          isUploadBody,
          isValidation,
          errId,
          errString,
        }),
        'data-testid': err['data-testid'] || defaultProperties['data-testid'],
      });
    },
    [showPopup],
  );

  return { catchPopupError, catchPopup, showPopup, hidePopup, properties };
};

export default usePopup;
